import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';
import { isEmpty } from "lodash";
import { stringIsNullOrEmpty } from "util/Utility";

class ProjectDao extends DataAccessObject {

    async getProjectList(token, queryParams, trashed) {
        let url = ApiUrl._API_GET_PROJECT;

        if (!isEmpty(queryParams)) {
          let queryStr = new URLSearchParams(queryParams).toString();
          if (!stringIsNullOrEmpty(queryStr)) {
            url += `?${queryStr}`;//setquery before the trashed statement
          }
        }
        return this.get(url+ "&trashed="+trashed, token);
        //return this.get(ApiUrl._API_GET_PROJECT + "?trashed="+ trashed, token);
    }
    async getProjects(token, trashed) {
        return this.get(ApiUrl._API_GET_PROJECT + "/all" + "?trashed="+ trashed, token);
    }
    async getProjectStatistics(token) {
        return this.get(ApiUrl._API_PROJECT + "/statistics" , token);
    }

    async createProject(data, token) {
        return this.post(ApiUrl._API_CREATE_PROJECT, data, token);
    }
    async createContact(data, token) {
        return this.post(ApiUrl._API_CREATE_PROJECT_CONTACTS, data, token);
    }
    async createContract(data, token) {
        return this.post(ApiUrl._API_CREATE_PROJECT_CONTRACTS, data, token);
    }
    async updateProject(data, token) {
        return this.post(ApiUrl._API_UPDATE_PROJECT, data, token);
    }
    async updateContact(data, token) {
        return this.post(ApiUrl._API_UPDATE_PROJECT_CONTACTS, data, token);
    }
    async updateContract(data, token) {
        return this.post(ApiUrl._API_UPDATE_PROJECT_CONTRACTS, data, token);
    }

    async findProjectById(token, id) {
        return this.get(ApiUrl._API_GET_PROJECT_BY_ID + "?id=" + id , token);
    }

    async deleteProject(data, token) {
        return this.delete(ApiUrl._API_PROJECT + "/delete/" + data, token);
    }
    
    async completeProject(data, token) {
        return this.post(ApiUrl._API_PROJECT + "/complete/" + data, data, token);
    }

    async closeProject(data, token) {
        return this.post(ApiUrl._API_PROJECT + "/close/" + data, data, token);
    }
    
    async updateProject(data, token) {
        return this.post(ApiUrl._API_UPDATE_PROJECT, data, token);
    }

    async getProjectTaskById(projectId, token) {
        return this.get(`${ApiUrl._API_GET_PROJECT_TASKS_BY_ID}/${projectId}`, token);
    }

    async removeProjectTaskById(data, token) {
        return this.post(ApiUrl._API_REMOVE_PROJECT_TASK, data, token);
    }

    async updateTask(data, token) {
        return this.postForm(ApiUrl._API_UPDATE_TASK, data, token);
    }

    async getServiceAgentsList(token) {
        return this.get(ApiUrl._API_SERVICE_AGENT_LIST, token);
    }

    // async completeProject(data, token) {
    //     return this.post(ApiUrl._API_PROJECT_COMPLETE, data, token);
    // }
}

export default ProjectDao;
