import React, { useMemo } from "react";
import ReactTable from 'components/react-table/ReactTable';
import Notiflix from "notiflix";

const DocumentTable = ({ data, tableClickEvent, setOpen, tableRef, deleteDocumentsList, ...rest }) => {
    const columns = useMemo(() => [
        
          {
            Header: "Filename",
            accessor: "documents.name",     
          }, 
          {
            Header: "Filesize",
            accessor: "documents.fileSize",     
          },  
          {
            Header: "Contract Type",
            accessor: "contractType",
          },  
          {
            Header: "Remarks",
            accessor: "remarks",     
          },
          {
            Header: " ",
            width:70,
            Cell: ({ row, index }) => (
              <button type="button" className="btn expand-theme btn-round"
              style={{padding:"0px"}}
              title="Delete Document"
              onClick={() => {
                    deleteDocumentsList(row);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", textAlign: "right", padding:"0px"},
          },
    ]);
    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default DocumentTable;