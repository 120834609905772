import React, { useMemo } from "react";
import ReactTable from 'components/react-table/ReactTable';

const ProjectUnitSavedTable = ({ data, tableClickEvent, setOpen, tableRef, deleteSavedUnit, ...rest }) => {
    
    const columns = useMemo(() => [
          {
            Header: "Product Type",
            accessor: "product_type",     
          },
          {
            Header: "Elevator Model ID",
            accessor: "elevator.serial_number",     
          },  
          {
            Header: "Project Status",
            accessor: "project_status",     
          },  
          {
            Header: "Shop Drawing Status",
            accessor: "shop_drawing_status",
          },  
          {
            Header: "Shop Drawing",
            accessor: "shop_drawing_original",     
          },  
          {
            Header: "Work Order Status",
            accessor: "work_order_status",     
          },  
          {
            Header: "Work Order",
            accessor: "work_order_original",   
          },  
          {
            Header: "JKKP Status",
            accessor: "jkkp_status",     
          },
          {
            Header: "Testing & Commissioning Form",
            accessor: "testing_commissioning_original",     
          },  
          {
            Header: "Completion Form",
            accessor: "completion_original",     
          },  
          {
            Header: "Handover Form",
            accessor: "handover_original",   
          }, 
          {
            Header: " ",
            width:70,
            Cell: ({ row, index }) => (
              <button type="button" className="btn expand-theme btn-round"
              style={{padding:"0px"}}
              title="Delete Unit"
              onClick={() => {
                    deleteSavedUnit(row.original);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", textAlign: "right", padding:"0px"},
          },

    ]);

    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default ProjectUnitSavedTable;