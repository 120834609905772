import React, { useMemo, useState, useRef, useEffect, forwardRef,useCallback } from "react";
import { Block } from "notiflix";
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Notiflix from "notiflix";
import { useHistory, Link } from 'react-router-dom';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import ElevatorModelDao from "data/ElevatorModelDao";
import { Config, ConfigEnum } from 'util/Config';
import ElevatorModelTable from "components/tables/ElevatorModelTable";
import { handleSetPageOptions } from "util/Utility";

///<summary>
///Author: Sim
///</summary>
const ElevatorModelList = props => {
    const [modal, setModal] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [project, setProject] = useState(null);
    const [open, setOpen] = useState(false);
    const [sneId, setSneId] = useState(null);
    const [isNewCust, setIsNewCust] = useState(false);
    const [tags, setTags] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [modelList, setModelList] = useState([]);
    const [listQueryParams, setListQueryParams] = useState();
    const _tableRef = useRef();
    let dao = useMemo(() => new ElevatorModelDao(), []);

    const getElevatorModels = useCallback(
        async (params) => {
          Block.circle("#elevatorModelTable");
    
          await dao.getElevatorModelList(token, params, 1).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
              var data = response[ApiKey._API_DATA_KEY];
              var models = [];
    
              data.map((item) => {
                models.push(item);
              });
    
              setModelList(models);
              handleSetPageOptions(_tableRef, response[ApiKey._API_TOTAL_KEY] ?? 0);
            } else {
              Notiflix.Report.failure(
                "Error",
                "Failed to load elevator models. Please try again later"
              );
            }
          });
    
          Block.remove("#elevatorModelTable");
        },
        [token, dao]
      );
    
    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    useEffect(() => {
        console.log(listQueryParams);
        listQueryParams && getElevatorModels(listQueryParams);
      }, [getElevatorModels, listQueryParams]);


    return (
        <>
            <div style={{ maxHeight: '65vh' }} id="elevatorModelTable">
                <ElevatorModelTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h2 className="m-b-0">Elevator Model List</h2>                            
                                <div className="btn btn-themed ml-2">
                                <span>New Model  </span>
                                <i className="fa fa-plus"></i>                            
                                </div>                            
                        </div>
                    )}        
                    setQueryParams={setListQueryParams}          
                    data={modelList}                    
                    tableRef={_tableRef}
                />
            </div>
        </>
    )
}

export default ElevatorModelList;

