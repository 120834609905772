import React, { useMemo, useState, useRef, useEffect, forwardRef, useCallback } from "react";
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { PaymentStatuses, States, PrefixTypes, PostfixTypes, ProductTypes, ProjectStatuses,
         ShopDrawingStatuses, WorkOrderStatuses, JKKPStatuses, ApiKey, Colors
        } from "util/Constant";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";  
import CustomDatePicker from "components/form/CustomDatePicker";
import NewProjectFileUpload from "components/form/NewProjectFileUpload";
import ProjectUnitTable from "components/tables/ProjectUnitTable";
import ProjectUnitSavedTable from "components/tables/ProjectUnitSavedTable";
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import moment from 'moment';
import { Block, Loading } from "notiflix";
import Notiflix from "notiflix";
import ElevatorModelDao from "data/ElevatorModelDao";
import ProjectDao from "data/ProjectDao";
import ReactTable from "components/react-table/ReactTable";
import { isEmpty } from "lodash";


const GeneralInfo =  (props) => {
    const [showUnit, setShowUnit] = useState(false);
    const [unitList, setUnitList] = useState([]);
    const [unitsInfo, setUnitsInfo] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [fileName, setFileName] = useState("");
    const [shopDrawing, setShopDrawing] = useState("");
    const [workOrder, setWorkOrder] = useState("");
    const [testingCommissioningForm, setTestingCommissioningForm] = useState("");
    const [completionForm, setCompletionForm] = useState("");
    const [handoverForm, setHandoverForm] = useState("");
    const [shopDrawingBase64, setShopDrawingBase64] = useState("");
    const [workOrderBase64, setWorkOrderBase64] = useState("");
    const [testingCommissioningFormBase64, setTestingCommissioningFormBase64] = useState("");
    const [completionFormBase64, setCompletionFormBase64] = useState("");
    const [handoverFormBase64, setHandoverFormBase64] = useState("");
    const { register, control, handleSubmit, setValue, errors, watch, reset, successCallback } = useForm({
        status: 0,
        priority: 0,
    });

    const history = useHistory();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    {/*new function as handler */}
    const showUnitClicked = (data) => {
      setShowUnit((prev) => !prev);
    };

    const [modelList, setModelList] = useState([]);
    const [models, setModels] = useState([]);
    const [listQueryParams, setListQueryParams] = useState();
    const _tableRef = useRef();
    let dao = useMemo(() => new ElevatorModelDao(), []);
    
    const {
      handleNext,
      setProjectId,
      generalInfo,
      setGeneralInfo,
      units,
      setUnits
    } = props;

    //use generalInfo?.units to populate old data,
    //can map the thing first(loop)
    //then use (row.original.id === generalInfo?.units.id) only delete
    // use function deleteSavedUnit

    const getElevatorModels = useCallback(
      async () => {
        await dao.getElevatorModels(token, 0).then((response) => {
          if (response[ApiKey._API_SUCCESS_KEY]) {
            var data = response[ApiKey._API_DATA_KEY];
            var models = [];
  
            data.map((item) => {
              var obj = {
                  label : item.serial_number,
                  value : item.id,
              }
              models.push(obj);
            });

            setModels(data);
            console.log(models)    
            setModelList(models);
          } else {
            Notiflix.Report.failure(
              "Error",
              "Failed to load model. Please try again later"
            );
          }
        });
      },
      [token, dao]
    );

    
    useEffect(() => {
      getElevatorModels(listQueryParams);
    }, [getElevatorModels]);

    useEffect(()=> {
      console.log(unitList);
    },[unitList]);

    const addToUnitList = async (data) => {

      if(watch("prefix") == undefined || watch("postfix") == undefined ||
      watch("productType") == undefined || watch("model") == undefined ||
      watch("projectStatus") == undefined || watch("shopDrawingStatus") == undefined ||
      watch("shopDrawing") == undefined || watch("workOrderStatus") == undefined ||
      watch("workOrder") == undefined || watch("jkkpStatus") == undefined ||
      watch("testingCommissioningDate") == undefined || watch("testingCommissioningForm") == undefined ||
      watch("completionDate") == undefined || watch("completionForm") == undefined ||
      watch("handoverDate") == undefined || watch("handoverForm") == undefined){
        Notiflix.Report.failure("Error","Fields are empty. Please fill in the empty fields.");
      } else {
        const [
          shopDrawingFileBase64,
          workOrderFileBase64,
          testingCommissioningFormFileBase64,
          completionFormFileBase64,
          handoverFormFileBase64
        ] = await Promise.all([
          blobToBase64(watch("shopDrawing")),
          blobToBase64(watch("workOrder")),
          blobToBase64(watch("testingCommissioningForm")),
          blobToBase64(watch("completionForm")),
          blobToBase64(watch("handoverForm"))
        ]);
      
        var findModel = models.find(x => x.id === watch("model"));

          console.log(findModel);
      //add the units into an array for dynamic display n to be stored afterwards
      setUnitList([...unitList,
        {
          //serialNumber: EnumPrefix[watch("prefix")] + " xxxxx" + EnumPostfix[watch("postfix")], 
          prefix: watch("prefix"),
          postfix: watch("postfix"),
          productType: watch("productType"),
          modelId: watch("model"),//EnumModels[watch("model")],
          model: findModel.serial_number,
          projectStatus: watch("projectStatus"),
          shopDrawingStatus: watch("shopDrawingStatus"),
          shopDrawing,
          workOrderStatus: watch("workOrderStatus"),
          workOrder,
          jkkpStatus: watch("jkkpStatus"),
          testingCommissioningForm,
          completionForm,
          handoverForm,
          testingCommissioningDate: moment(watch("testingCommissioningDate")).format("YYYY-MM-DD"),
          completionDate: moment(watch("completionDate")).format("YYYY-MM-DD"),
          handoverDate: moment(watch("handoverDate")).format("YYYY-MM-DD"),
          
          //files handling
          shopDrawingFileBase64 : shopDrawingFileBase64,
          workOrderFileBase64 :workOrderFileBase64,
          testingCommissioningFormFileBase64 :testingCommissioningFormFileBase64,
          completionFormFileBase64 :completionFormFileBase64,
          handoverFormFileBase64 : handoverFormFileBase64,
        }
          ]);
          showUnitClicked();
      }

    };
    const deleteUnit = async(row) => {
      var index = row.index;
      console.log(unitList);
      Notiflix.Confirm.show(
        "Confirmation",
        "Are you sure to delete this row?",
        "Yes",
        "No",
        function () {
          console.log(unitList);
          let tempUnitList = [...unitList];

          tempUnitList.splice(index,1);
          console.log(tempUnitList);
          setUnitList(tempUnitList);          
        }
      );
    }

    const deleteSavedUnit = async(row) => {
      var id = row.id;
      var index = 0;
      console.log(generalInfo.units);
      Notiflix.Confirm.show(
        "Confirmation",
        "Are you sure to delete this row?",
        "Yes",
        "No",
        function () {
          console.log(generalInfo.units);
          const updatedUnits = generalInfo.units.filter((unit) => unit.id !== id);
            
            // Update the state with the new array
            setGeneralInfo((prevGeneralInfo) => ({
                ...prevGeneralInfo,
                units: updatedUnits,
            }));   
        }
      );
    }

    const blobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onloadend = () => {
          // Extract Base64 string from the result
          const base64String = reader.result.split(',')[1];
          resolve(base64String); // Return the Base64 string
        };
  
        reader.onerror = reject;
        reader.readAsDataURL(blob[0]); // This converts the Blob to a Data URL (Base64)
      });
    };

    useEffect(() => {
      reset({
          projectTitle: generalInfo?.project_title || "", // Reset with new default value
          siteProjectTitle: generalInfo?.site_project_title || "",
          buildingDesignType: generalInfo?.building_design_type || "",
          projectPhase: generalInfo?.project_phase || "",
          quantity: generalInfo?.quantity || "",
          paymentStatus : generalInfo?.payment_status || "",
          addressLine1 : generalInfo?.address_line_1 || "",
          addressLine2 : generalInfo?.address_line_2 || "",
          postalCode : generalInfo?.postal_code || "",
          cityTown : generalInfo?.city_town || "",
          states : generalInfo?.states || "",
          longitude : generalInfo?.longitude || "",
          latitude : generalInfo?.latitude || ""

          // Add other fields as needed
      });
    }, [generalInfo, reset]);

    const onSubmit = async(data) => {

        Notiflix.Confirm.show(
          'Notiflix Confirm',
          'Do you want to continue?',
          'Yes',
          'No',
          async () => {
            Loading.init({
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                svgColor: Colors.THEME,
                messageColor: Colors.WHITE,
            });

            Loading.circle();

            data["units"]= unitList;
            console.log(data);
            console.log(unitList);
  
            //data passing then it returns the id, then use the state setProjectId
            let dao = new ProjectDao();
            // can make a if else statment to differ if its "create" or "update" (below)
            if(generalInfo){
              //alert(generalInfo.id)
              var obj ={
                ...data,
                generalInfo,
                unitList,
                projectId: generalInfo.id
              }
              console.log(obj)//correct edy units use obj/data->generalInfo->units when recording back to db
              await dao.updateProject(obj,token).then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                  let project = responseJson[ApiKey._API_DATA_KEY];
                  Notiflix.Report.init({ plainText: false });
                  Notiflix.Report.success("Success",`Successfully updated model.`,"Okay",handleNext());
                } else {
                  Notiflix.Report.failure("Error",responseJson[ApiKey._API_MESSAGE_KEY]);
                } 
              })
                  //handleNext()
            }else{
              await dao.createProject(data, token).then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                  let projectId = responseJson[ApiKey._API_DATA_KEY];
                  setProjectId(projectId);
                  //alert(projectId)
      
                  Notiflix.Report.init({ plainText: false });
                  Notiflix.Report.success(
                    "Success",
                    `Successfully created model.`,
                    "Okay",
                    handleNext()
                  );
                } else {
                  Notiflix.Report.failure(
                    "Error",
                    responseJson[ApiKey._API_MESSAGE_KEY]
                  );
                } 
              })

            }Loading.remove();

        }
        ); 
        
    }

    return (
    <form id="projectForm" onSubmit={handleSubmit(onSubmit)}>
    <Row style={{backgroundColor:"#F0F9FF", margin: "10px"}}>
                <Col md={10}>
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                      General Info
                  </h1>
                </Col>
                <Col md={2}>
                <button type="reset" className='btn btn-default d-flex-center' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                </Col>
                <Col md={12}>
                    <label className="custom-input-label">Project Title</label>
                    <CustomInput
                      {...register("projectTitle", {
                        required: "Project Title is required",
                      })}
                      name="projectTitle"
                      label="Project Title"              
                      defaultValue={generalInfo?.project_title}
                      showLabel
                    />
                </Col>        
                <Col md={12}>
                    <label className="custom-input-label">Site Project Title</label>
                    <CustomInput
                      {...register("siteProjectTitle", {
                        required: "Site Project Title is required",
                      })}
                      name="siteProjectTitle"
                      label="Site Project Title"                
                      defaultValue={generalInfo?.site_project_title}
                      showLabel
                    />
                </Col>         
                <Col md={6}>
                    <label className="custom-input-label">Building Design Type (optional)</label>
                    <CustomInput
                      {...register("buildingDesignType", {
                        required: false,
                      })}   
                      name="buildingDesignType"
                      label="Building Design Type"                
                      defaultValue={generalInfo?.building_design_type}
                      showLabel
                    />
                </Col>   
                <Col md={6}>
                    <label className="custom-input-label">Project Phase (optional)</label>
                    <CustomInput
                      {...register("projectPhase", {
                        required: false,
                      })}   
                      name="projectPhase"
                      label="Project Phase"                
                      defaultValue={generalInfo?.project_phase}
                      showLabel
                    />
                </Col>      
                <Col md={6}>
                    <label className="custom-input-label">Quantity</label>
                    <CustomInput
                      {...register("quantity", {
                        required: "Quantity is required",
                      })}                    
                      name="quantity"
                      label="Quantity"      
                      defaultValue={generalInfo?.quantity}
                      type = "number"          
                      showLabel
                    />
                </Col>      
                <Col md={6}>
                    <label className="custom-input-label">Payment Status</label>
                    <CustomSelect
                      control={control}
                      name="paymentStatus"
                      label="Payment Status"
                      placeholder="Payment Status *"
                      options={PaymentStatuses}
                      rules={{
                          required: true,
                      }}
                      defaultValue={generalInfo?.payment_status}
                    />                                        
                </Col>  
                
                <Col md={12}>
                <br />
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                      Site Address
                  </h1>
                </Col>
                <Col md={12}>
                    <label className="custom-input-label">Address Line 1</label>
                    <CustomInput
                      {...register("addressLine1", {
                        required: "Address Line 1 is required",
                      })}
                      name="addressLine1"
                      label="Address Line 1"                
                      defaultValue={generalInfo?.address_line_1}
                      showLabel
                    />
                </Col> 
                <Col md={12}>
                    <label className="custom-input-label">Address Line 2</label>
                    <CustomInput
                      {...register("addressLine2", {
                        required: false,
                      })}
                      name="addressLine2"
                      label="Address Line 2"                
                      defaultValue={generalInfo?.address_line_2}
                      showLabel
                    />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">Postal Code</label>
                    <CustomInput
                      {...register("postalCode", {
                        required: "Postal Code is required",
                      })}
                      name="postalCode"
                      label="Postal Code"                
                      defaultValue={generalInfo?.postal_code}
                      showLabel
                    />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">City / Town</label>
                    {/* changed to text input */}
                    <CustomInput
                      {...register("cityTown", {
                        required: "City / Town is required",
                      })}
                      name="cityTown"
                      label="City / Town"                
                      defaultValue={generalInfo?.city_town}
                      showLabel
                    />
                </Col>
                <Col md={4}>
                    <label className="custom-input-label">States</label>
                    <CustomSelect
                      control={control}
                      name="states"
                      label="States"
                      placeholder="Choose the state"
                      options={States}
                      rules={{
                          required: true,
                      }}
                      defaultValue={generalInfo?.states}
                    /> 
                </Col>
                <Col md={12}>
                    <label className="custom-input-label">GPS Location</label>
                </Col>
                <Col md={6}>
                    <label className="custom-input-label" style={{fontSize: "11px", fontWeight: "normal"}}>Longitude</label>
                    <CustomInput
                      {...register("longitude", {
                        required: "Longitud is required",
                      })}
                      name="longitude"
                      label="Longitude"                
                      defaultValue={generalInfo?.longitude}
                      showLabel
                    />
                </Col><Col md={6}>
                    <label className="custom-input-label" style={{fontSize: "11px", fontWeight: "normal"}}>Latitude</label>
                    <CustomInput
                      {...register("latitude", {
                        required: "Latitude is required",
                      })}
                      name="latitude"
                      label="Latitude"                
                      defaultValue={generalInfo?.latitude}
                      showLabel
                    />
                </Col>    
              {isEmpty(generalInfo?.deleted_at) && (<>
                <Col md={10}>                
                <br />
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                      Lists of Units
                  </h1>
                </Col>
                <Col md={2} style={{marginTop: "auto"}}>
                  <div className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center"}} onClick={showUnitClicked}>
                    <span>Add new unit +</span>
                  </div> </Col>
                  { /* action handler if __ is true then to what from here eh is put in the table inside here then rmb onClick ^ put the function that setShowUnit to true eh function*/
                    showUnit && (
                      <Col md={12}>
                        <Row style={{backgroundColor:"white", margin: "10px"}}>
                          <Col><br /></Col>
                            <Col md={12}>
                              <h4>
                                  Add New Unit
                              </h4>
                            </Col>
                            <Col md={12}>
                              <label className="custom-input-label">Serial Number</label>
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Prefix</label>
                                <CustomSelect
                                  control={control}
                                  name="prefix"
                                  label="Prefix"
                                  placeholder="Choose a prefix"
                                  options={PrefixTypes} 
                                  rules={{
                                      required: true,
                                  }}
                                /> 
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Postfix</label>
                              <CustomSelect
                                  control={control}
                                  name="postfix"
                                  label="Postfix"
                                  placeholder="Choose a postfix"
                                  options={PostfixTypes}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Product Type</label>
                                <CustomSelect
                                  control={control}
                                  name="productType"
                                  label="Product Type"
                                  placeholder="Choose a product type"
                                  options={ProductTypes}
                                  rules={{
                                      required: true,
                                  }}
                                /> 
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Model</label>
                              <CustomSelect
                                  control={control}
                                  name="model"
                                  label="Model"
                                  placeholder="Choose a model"
                                  options={modelList}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Project Status</label>
                              <CustomSelect
                                  control={control}
                                  name="projectStatus"
                                  label="Project Status"
                                  placeholder="Choose the project status"
                                  options={ProjectStatuses}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Shop Drawing Status</label>
                              <CustomSelect
                                  control={control}
                                  name="shopDrawingStatus"
                                  label="Shop Drawing Status"
                                  placeholder="Choose the shop drawing status"
                                  options={ShopDrawingStatuses}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={6}>
                                <label className="custom-input-label">Shop Drawing</label>
                                <NewProjectFileUpload
                                  control={control}
                                  name="shopDrawing"
                                  setShopDrawing={setShopDrawing}
                                />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Work Order Status</label>
                              <CustomSelect
                                  control={control}
                                  name="workOrderStatus"
                                  label="Work order Status"
                                  placeholder="Choose the work order status"
                                  options={WorkOrderStatuses}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={6}>
                                <label className="custom-input-label">Work Order</label>
                                <NewProjectFileUpload
                                  control={control}
                                  name="workOrder"
                                  setWorkOrder={setWorkOrder}
                                /> 
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">JKKP Status</label>
                              <CustomSelect
                                  control={control}
                                  name="jkkpStatus"
                                  label="JKKP Status"
                                  placeholder="Choose the JKKP status"
                                  options={JKKPStatuses}
                                  rules={{
                                      required: true,
                                  }}
                                />
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Testing & Commissioning Date</label>
                              <CustomDatePicker
                                control={control}
                                {...register("testingCommissioningDate", false)}
                                name="testingCommissioningDate"
                                label="Testing & Commissioning Date"
                                onChange={null}
                              />
                            </Col>
                            <Col md={4}>
                              <label className="custom-input-label">Testing & Commissioning Form</label>
                              <NewProjectFileUpload
                                control={control}
                                name="testingCommissioningForm"
                                setTestingCommissioningForm={setTestingCommissioningForm}
                              />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Completion Date</label>
                              <CustomDatePicker
                                control={control}
                                {...register("completionDate", false)}
                                name="completionDate"
                                label="Completion Date"
                                onChange={null}
                              />
                            </Col>
                            <Col md={6}>
                                <label className="custom-input-label">Completion Form</label>
                              <NewProjectFileUpload
                                control={control}
                                name="completionForm"
                                setCompletionForm={setCompletionForm}
                              />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Handover Date</label>
                              <CustomDatePicker
                                control={control}
                                {...register("handoverDate", false)}
                                name="handoverDate"
                                label="Handover Date"
                                onChange={null}
                              />
                            </Col>
                            <Col md={6}>
                              <label className="custom-input-label">Handover Form</label>
                              <NewProjectFileUpload
                                control={control}
                                name="handoverForm"
                                setHandoverForm={setHandoverForm}
                              />
                            </Col>
                            <Col md={12}>
                              <br />
                              <button type="button" onClick={() => addToUnitList()} className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", marginRight:"6px", width: "100%"}}>Add to Unit List</button>
                              <br />
                            </Col>
                        </Row>
                      </Col> 

                    ) 
                  }
                   
                   
                <Col md={12}>                
                  <br />
                  <ProjectUnitTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h1 className="page-subheader">Units List</h1>      
                        </div>
                    )}        
                    //setQueryParams={setListQueryParams}          
                    data={unitList}
                    deleteUnit={deleteUnit}            
                  />
                <br /><br />
                </Col> 
                </>)}
                {
                    generalInfo && (
                      <Col md={12}>                
                        <br />
                        <ProjectUnitSavedTable
                          tableTitle={(
                              <div className="d-flex flex-row align-items-center justify-between w-100">
                                  <h1 className="page-subheader">Current Units List</h1>      
                              </div>
                          )}                 
                          data={generalInfo?.units}
                          deleteSavedUnit={deleteSavedUnit}
                          tableRef={_tableRef}                   
                        />
                      <br /><br />
                      </Col> 
                    ) 
                }
                
                <Col md={12}>
                {(generalInfo?.deleted_at) && (<><button className="btn btn-themed btn-block" onClick={handleNext}>Next</button><br /></>)}
                {isEmpty(generalInfo?.deleted_at) && (<><button type="submit" className="btn btn-themed btn-block">Save & Next</button>
                <br /></>)}
                </Col> 
                </Row>
                </form>
    )
    
}

export default GeneralInfo;