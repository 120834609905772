import React, { useMemo } from "react";
import ReactTooltip from 'react-tooltip';
import moment from "moment";
import { DateFormat, AppFeatureKeys, Permission } from 'util/Constant';
import ReactTable from 'components/react-table/ReactTable';

/// <summary>
/// Author: Samuel
/// </summary>
const ElevatorModelTable = ({ data, tableClickEvent, setOpen, tableRef, ...rest }) => {
    const columns = useMemo(() => [
      {
        Header: "Serial Number",
        Cell: ({ row }) => <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}><b>{row.original.serial_number}</b></div>,
      },
      {
        Header: "Product Type",
        Cell: ({ row }) => (
          <>
            <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
               {row.original.product_type}
            </div>
          </>
        ),
      },
      {
        Header: "Model Type",
        Cell: ({ row }) => (
          <>
            <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
               {row.original.model_type}
            </div>
          </>
        ),
      },
      {
        Header: "Rated Load (kg)",
        Cell: ({ row }) => (
          <>
            <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
               {row.original.rated_load}
            </div>
          </>
        ),
      },
      {
        Header: "Rated Speed (mpm)",
        Cell: ({ row }) => (
          <>
            <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
               {row.original.rated_speed}
            </div>
          </>
        ),
      },
      {
        Header: "Number Of Stops",
        Cell: ({ row }) => (
          <>
            <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
               {row.original.number_of_stops}
            </div>
          </>
        ),
      },
      {
        Header: "JKKP Requirement",
        Cell: ({ row }) => (
          <>
            <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
               {row.original.jkkp_requirement}
            </div>
          </>
        ),
      },
      {
        Header: "Creation Date",
        Cell: ({ row }) => (
          <>
            <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
               {moment(row.original.created_at).local().format("DD-MM-YYYY hh:mm:ss A")}
            </div>
          </>
        ),
      },
      {
        Header: "Modified Date",
        Cell: ({ row }) => (
          <>
            <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
               {moment(row.original.updated_at).local().format("DD-MM-YYYY hh:mm:ss A")}
            </div>
          </>
        ),
      },
      // {
      //   Header: " ",
      //   Cell: ({ row }) => (
      //     <>
           
      //     </>
      //   ),
      //   disableSortBy: true,
      //   disableFilters: true,
      //   style: { overflow: "visible", textAlign: "right" },
      //   sticky: "right",
  
      // },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ]);

    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default ElevatorModelTable;