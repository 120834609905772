import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';
import { isEmpty } from "lodash";
import { stringIsNullOrEmpty } from "util/Utility";

/// <summary>
/// Author: Christopher Chan
/// </summary>
class ElevatorModelDao extends DataAccessObject {

    async getElevatorModelList(token, queryParams, trashed) {
        let url = ApiUrl._API_ELEVATOR_MODELS;

        if (!isEmpty(queryParams)) {
          let queryStr = new URLSearchParams(queryParams).toString();
          if (!stringIsNullOrEmpty(queryStr)) {
            url += `?${queryStr}`;//setquery before the trashed statement
          }
        }
        return this.get(url+ "&trashed="+trashed, token);
    }

    async getElevatorModels(token, trashed) {
        return this.get(ApiUrl._API_ELEVATOR_MODELS + "/all" + "?trashed="+ trashed, token);
    }

    async createOrUpdateElevatorModel(data, token) {
        return this.post(ApiUrl._API_ELEVATOR_MODELS, data, token);
    }

    async deleteElevatorModel(data, token) {
        return this.delete(ApiUrl._API_ELEVATOR_MODELS + "/delete/" + data, token);
    }

    async updateElevatorPart(data, token) {
        return this.put(ApiUrl._API_MODIFY_ELEVATOR_PART, data, token);
    }

    async createElevatorPart(data, token) {
        return this.post(ApiUrl._API_CREATE_ELEVATOR_PART, data, token);
    }

    async deleteElevatorPart(data, token) {
        return this.delete(ApiUrl._API_DELETE_ELEVATOR_PART+ "/" + data, token);
    }
}

export default ElevatorModelDao;
