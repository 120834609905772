import React, { useMemo } from "react";
import ReactTable from 'components/react-table/ReactTable';

const ProjectUnitTable = ({ data, tableClickEvent, setOpen, tableRef, deleteUnit, ...rest }) => {
    
    const columns = useMemo(() => [
          {
            Header: "Product Type",
            accessor: "productType",     
          },
          {
            Header: "Model",
            accessor: "model",     
          },  
          {
            Header: "Project Status",
            accessor: "projectStatus",     
          },  
          {
            Header: "Shop Drawing Status",
            accessor: "shopDrawingStatus",
            //disableSortBy: true,
            //disableFilters: true,
            //style: { overflow: "visible", textAlign: "right" },
            //sticky: "right"
          },  
          {
            Header: "Shop Drawing",
            accessor: "shopDrawing",     
          },  
          {
            Header: "Work Order Status",
            accessor: "workOrderStatus",     
          },  
          {
            Header: "Work Order",
            accessor: "workOrder",   
          },  
          {
            Header: "JKKP Status",
            accessor: "jkkpStatus",     
          },
          {
            Header: "Testing & Commissioning Form",
            accessor: "testingCommissioningForm",     
          },  
          {
            Header: "Completion Form",
            accessor: "completionForm",   
          },  
          {
            Header: "Handover Form",
            accessor: "handoverForm",     
          },
          {
            Header: " ",
            width:70,
            Cell: ({ row, index }) => (
              <button type="button" className="btn expand-theme btn-round"
              style={{padding:"0px"}}
              title="Delete Unit"
              onClick={() => {
                    deleteUnit(row);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", textAlign: "right", padding:"0px"},
          },

          // <button type="button" className="btn expand-theme btn-round ml-2 ">
          //     <i class="fas fa-times"></i>
          // </button>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ]);

    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default ProjectUnitTable;