import React, { useMemo } from "react";
import ReactTooltip from 'react-tooltip';
import moment from "moment";
import { DateFormat, AppFeatureKeys, Permission } from 'util/Constant';
import ReactTable from 'components/react-table/ReactTable';
import { useHistory } from "react-router-dom";
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import Notiflix from "notiflix";
import classnames from 'classnames';

/// <summary>
/// Author: Samuel
/// </summary>
const ProjectTable = ({ data, tableRef,  canPreviousPage ,canNextPage, successCallback, disabledApiPagination, setQueryParams, deleteProject, completeProject, closeProject, ...rest }) => {
  
  const history = useHistory();
    const columns = useMemo(() => [
        {
            Header: "Project Serial Number",
            Cell: ({ row }) => <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}><b>{row.original.prefix1}{row.original.year}-{row.original.prefix2}{row.original.project_serial_number}</b></div>,
        },
        {
            Header: "Project Title",
            Cell: ({ row }) => (
              <>
                <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
                   {row.original.project_title}
                </div>
              </>
            ),
        },
        {
            Header: "Site Project Title",
            Cell: ({ row }) => (
              <>
                <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
                   {row.original.site_project_title}
                </div>
              </>
            ),
        },
        {
          Header: "Project Status",
          Cell: ({ row }) => {
            let status = row.original.project_status;
            let classes = classnames("label custom-status-label", {
              "label-secondary": status === "Deleted",//0
              "label-success": status === "Completed",//1
              "label-cyan": status === "Draft",//-2
              "label-warning": status === "Processing",//-2
              "label-danger": status === "Closed",//-1
            });
    
            return <div className={classes}>
                      {row.original.project_status}
                    </div>;
          },
        },
        {
            Header: "Building Design Type",
            Cell: ({ row }) => (
              <>
                <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
                   {row.original.building_design_type}
                </div>
              </>
            ),
        },
        {
            Header: "Project Phase",
            Cell: ({ row }) => (
              <>
                <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
                   {row.original.project_phase}
                </div>
              </>
            ),
        },
        {
            Header: "Quantity",
            Cell: ({ row }) => (
              <>
                <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
                   {row.original.quantity}
                </div>
              </>
            ),
        },
        {
            Header: "Payment Status",
            Cell: ({ row }) => (
              <>
                <div style={{color: row.original.deleted_at ? 'grey' : 'black'}}>   
                   {row.original.payment_status}
                </div>
              </>
            ),
        },
        
        {
            Header: "Action",
            Cell: ({ row }) => (
              <>
                <div
                  className="btn-table"
                  onClick={() =>
                    history.push(
                      WebUrl._PROJECT_NEW_UPDATE.replace(
                        ":id",
                        row.original.id
                      ),
                      { details: row.original }
                    )
                  }
                >
                  <i className="fa fa-edit"></i>
                </div>
                <div
                    className="btn-table"
                    title="Delete Project"
                    onClick={() => {
                      Notiflix.Confirm.show(
                        "Confirmation",
                        "Do you sure to delete project?",
                        "Yes",
                        "No",
                        function () {
                          deleteProject(row.original);
                        }
                      );
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                <div
                    className="btn-table"
                    title="Complete Project"
                    onClick={() => {
                      Notiflix.Confirm.show(
                        "Confirm Complete",
                        "Do you sure to complete project?",
                        "Yes",
                        "No",
                        function () {
                          completeProject(row.original);
                        }
                      );
                    }}
                  >
                    <i className="fa fa-check"></i>
                  </div>
                <div
                    className="btn-table last-btn"
                    title="Close Project"
                    onClick={() => {
                      Notiflix.Confirm.show(
                        "Close Project",
                        "Do you sure to close project?",
                        "Yes",
                        "No",
                        function () {
                          closeProject(row.original);
                        }
                      );
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </div>
              </>
            ),        
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ]);

    return <ReactTable columns={columns} data={data} ref={tableRef} setQueryParams={setQueryParams} canNextPage={canNextPage} canPreviousPage={canPreviousPage} disabledApiPagination={disabledApiPagination} {...rest} />;
}

export default ProjectTable;