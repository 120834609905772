const _DEV_HOST = "http://localhost:8000";
const _SIT_HOST = "http://nicheapi.gzit.com.my";
const _PROD_HOST = "https://api.niche123.net";

/// <summary>
/// Author : Robin
/// Host for different Environment
/// </summary>
export class HostKey {
    static getApiHost = () => {
        switch (process.env.REACT_APP_ENV) {
            case "dev":
                return _DEV_HOST;
    
            case "sit":
                return _SIT_HOST;
    
            case "prod":
                return _PROD_HOST;
    
            default:
                return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? _DEV_HOST : _SIT_HOST;
        }
    }
}

export const _IMAGE_URL = `${HostKey.getApiHost()}/storage`;

/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";
    static _API_TOTAL_KEY = "totalCount";
    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_FORM_DATA = "multipart/form-data";
    static _API_POST = "POST";
    static _API_GET = "GET";
    static _API_DELETE = "DELETE";
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary>
export class ApiUrl {
    //neps 
    static _API_LOGIN = "/auth/login";
    static _API_VALIDATE_LOGIN = "/auth/user";
    static _API_LOGOUT = '/auth/logout';
    static _API_CONTRACT_LIST = "/contract";
    static _API_CREATE_CONTRACT = "/contract";
    static _API_EMPTY_CONTRACT_QUOTATION_LIST = "/quotation/emptyContract";
    static _API_UPLOAD_SIGNED_DOCUMENT = '/contract/update/status';
    static _API_UPDATE_CONTRACT = '/projectContract/update';
    static _API_GET_CONTRACT_SITES = "/projectContract/sites";
    static _API_GET_SITE_CONTRACTS = "/projectContract/site/contracts";
    static _API_QUOTATION_LIST = '/quotation/list';
    static _API_QUOTATION_ITEMS = '/quotation/items';
    static _API_GET_PROJECT = "/project/list";
    static _API_GET_PROJECT_NEW = "/project/newList";
    static _API_CREATE_PROJECT = '/project/create';
    static _API_CREATE_PROJECT_CONTACTS = '/project/create/contacts';
    static _API_CREATE_PROJECT_CONTRACTS = '/project/create/contracts';
    static _API_UPDATE_PROJECT = '/project/update';
    static _API_UPDATE_PROJECT_CONTACTS = '/project/update/contacts';
    static _API_UPDATE_PROJECT_CONTRACTS = '/project/update/contracts';
    static _API_DELETE_PROJECT = '/project/delete';

    static _API_GET_PROJECT_BY_ID = '/project/getProjectById';

    static _API_GET_PROJECT_TASKS_BY_ID = '/project/task/list';
    static _API_REMOVE_PROJECT_TASK = '/project/task/delete';
    static _API_UPDATE_TASK = '/project/task/update';
    static _API_GET_USER = '/user/list';
    static _API_SERVICE_AGENT_LIST = '/service-agent/list';
    static _API_PROJECT_COMPLETE = '/project/complete';
    static _API_CUSTOMER_LIST = "/customer/list";
    static _API_ELEVATOR_MODELS = "/elevator-models";
    static _API_PROJECT = "/project";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    //neps
    static _LOGIN = "/login";
    static _DASHBOARD = "/dashboard";
    static _CONTRACT = "/contract";
    static _PROJECT = "/project";
    static _PROJECT_LIST = "/project-list";
    static _PROJECT_TASKS = "/project/tasks";
    static _PROJECT_CREATE = "/project/create";
    static _PROJECT_NEW = "/project/new";
    static _PROJECT_NEW_UPDATE = "/project/new/update/:id";
    static _CUSTOMER = "/customer-list";
    static _ELEVATOR_MODELS = "/elevator-model-list";
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
    static _ENGLISH_GB = "en-GB";
    static _CHINESE_HK = "zh-HK";
    static _CHINESE_CN = "zh-CN";
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export class Status {
    static _DELETED = -2;
    static _REJECTED = -1;
    static _INACTIVE = 0;
    static _ACTIVE = 1;
    static _PENDING = 2;
    static _EXPIRED = 3;
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const BtnTypes = {
    ADD: 'add',
    DELETE: 'delete',
    VIEW: 'view',
    EDIT: 'edit',
    MISC: 'misc',
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const InputTypes = {
    INPUT: 'input',
    SELECT: 'select',
    RADIO: 'radio',
    PHONE: 'phone',
    TEXTAREA: 'textarea',
    ATTACHMENT: 'attachment',
    DATEPICKER: 'datepicker',
    CHECKBOX: 'checkbox',
    FILEUPLOAD: 'fileupload',
    IMAGEUPLOADER: 'imageuploader'
}

export const  PaymentStatuses = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Healthy', value: 'Healthy' },
    { label: 'Underpaid', value: 'Underpaid' },
    { label: 'Overdue', value: 'Overdue' },
    { label: 'Cleared', value: 'Cleared' }
];

/// <summary>
/// Author: Lewis
/// </summary>
export const SelectField = {
    STATUS_OPTIONS: [
        { label: 'Pending', value: 0 },
        { label: 'In Progress', value: 1 },
        { label: 'Completed', value: 2 }
    ],
    TYPE_OF_SERVICE_OPTIONS: [
        { label: 'Preventive Service', value: 'Preventive Service' },
        { label: 'Inspection Service', value: 'Inspection Service' },
    ],
    TYPE_OF_LIFT_OPTIONS: [
        { label: 'Passenger', value: 'Passenger' },
        { label: 'Goods', value: 'Goods' },
        { label: 'Hydraulic', value: 'Hydraulic' },
        { label: 'MRL', value: 'MRL' },
    ]
}

export const SidebarType = {
    _ACCOUTANT: 1,
    _ADMIN: 2
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const Colors = {
    THEME: '#5E96AE',
    OFF_THEME: '#eff6f3',
    WHITE: '#FFF',
    RED: '#f64e60',
    GRAY: '#dfe1e4',
    BLACK : '#000'
}

export const  States = [
    { label: 'Penang', value: 'Penang' },
    { label: 'Kuala Lumpur', value: 'Kuala Lumpur' },
    { label: 'Putrajaya', value: 'Putrajaya' },
    { label: 'Selangor', value: 'Selangor' },
    { label: 'Kedah', value: 'Kedah' },
    { label: 'Perlis', value: 'Perlis' },
    { label: 'Perak', value: 'Perak' },
    { label: 'Kelantan', value: 'Kelantan' },
    { label: 'Pahang', value: 'Pahang' },
    { label: 'Terengganu', value: 'Terengganu' },
    { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
    { label: 'Melaka', value: 'Melaka' },
    { label: 'Johor', value: 'Johor' },
    { label: 'Sabah', value: 'Sabah' },
    { label: 'Sarawak', value: 'Sarawak' },
    { label: 'Labuan', value: 'Labuan' }
];

export const  PrefixTypes = [
    { label: 'SNE', value: 'SNE' },
    { label: 'NE', value: 'NE' },
    { label: 'EX', value: 'EX' }
];
export const  PostfixTypes = [
    { label: 'A-18', value: 'A-18' },
    { label: 'H-18', value: 'H-18' },
    { label: 'D', value: 'D' },
    { label: 'G', value: 'G' },
    { label: 'VLP', value: 'VLP' },
    { label: 'C', value: 'C' },
    { label: 'DL', value: 'DL' },
    { label: 'S', value: 'S' },
    { label: 'H', value: 'H' },
    { label: 'SL', value: 'SL' },
    { label: 'IPL', value: 'IPL' },
    { label: 'CL', value: 'CL' },
    { label: 'RL', value: 'RL' },
    { label: 'HG', value: 'HG' },
    { label: 'VPL', value: 'VPL' },
    { label: 'PL', value: 'PL' },
    { label: 'HR', value: 'HR' },
    { label: 'TR', value: 'TR' },
    { label: 'LU', value: 'LU' },
    { label: 'TT', value: 'TT' },
    { label: 'HP', value: 'HP' },
    { label: 'GL', value: 'GL' },
    { label: 'MRL', value: 'MRL' },
    { label: 'TRL', value: 'TRL' },
    { label: 'HGL', value: 'HGL' },
    { label: 'TVL', value: 'TVL' },
    { label: 'MRLH', value: 'MRLH' },
    { label: 'MRLC', value: 'MRLC' },
    { label: 'MRLB', value: 'MRLB' },
    { label: 'B-18', value: 'B-18' },
    { label: 'H-16', value: 'H-16' }
];

export const  ProductTypes = [
    { label: 'Home Lift', value: 'Home Lift' },
    { label: 'OKU Lift', value: 'OKU Lift' },
    { label: 'Dumbwaiter', value: 'Dumbwaiter' },
    { label: 'Goods Hoist', value: 'Goods Hoist' },
    { label: 'Scissor Lift', value: 'Scissor Lift' },
    { label: 'Mini Passenger Lift', value: 'Mini Passenger Lift' },
    { label: 'Nano Lift', value: 'Nano Lift' },
    { label: 'Others', value: 'Others' }
];

export const  ProjectStatuses = [
    { label: 'Shop Drawing', value: 'Shop Drawing' },
    { label: 'Work Order', value: 'Work Order' },
    { label: 'In Production', value: 'In Production' },
    { label: '1st Batch Delivered', value: '1st Batch Delivered' },
    { label: '2nd Batch Delivered', value: '2nd Batch Delivered' },
    { label: '1st Batch Installed', value: '1st Batch Installed' },
    { label: '2nd Batch Installed', value: '2nd Batch Installed' },
    { label: 'Testing', value: 'Testing' },
    { label: 'Cleaning', value: 'Cleaning' },
    { label: 'Handover', value: 'Handover' },
];

export const  ShopDrawingStatuses = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Issued', value: 'Issued' },
    { label: 'Approved', value: 'Approved' }
];

export const  WorkOrderStatuses = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Issued', value: 'Issued' },
    { label: 'Approved', value: 'Approved' }
];

export const  JKKPStatuses = [
    { label: 'NA', value: 'NA' },
    { label: 'P', value: 'P' },
    { label: 'DA-R', value: 'DA-R' },
    { label: 'DA-A', value: 'DA-A'},
    { label: 'NTK', value: 'NTK' },
    { label: 'IA-R', value: 'IA-R' },
    { label: 'IA-A', value: 'IA-A' },
    { label: 'RR', value: 'RR' },
    { label: 'SI-R', value: 'SI-R' },
    { label: 'SI-D', value: 'SI-D' },
    { label: 'SI-C', value: 'SI-C' },
    { label: 'SI-A', value: 'SI-A' },
    { label: 'B', value: 'B' },
    { label: 'PMA', value: 'PMA' },
    { label: 'X', value: 'X' },
    { label: 'C', value: 'C' },
];


export const ProjectRole = [
    { label: "Owner (Developer)", value: "Owner (Developer)"},
    { label: "Owner (Individual)", value: "Owner (Individual)"},
    { label: "Architect", value: "Architect"},
    { label: "M & E", value: "M & E"},
    { label: "C & S", value: "C & S"},
    { label: "QS", value: "QS"},
    { label: "Main-con", value: "Main-con"},
    { label: "I.D. Sub-con", value: "I.D. Sub-con"},
    { label: "Infrastructure Sub-con", value: "Infrastructure Sub-con"},
    { label: "Builder Sub-con (lay bricks, plastering works)", value: "Builder Sub-con (lay bricks, plastering works)"},
    { label: "Electrical Sub-con", value: "Electrical Sub-con"},
    { label: "CCTV Sub-con", value: "CCTV Sub-con"},
    { label: "Smart Home Sub-con", value: "Smart Home Sub-con"},
    { label: "Plumbing Sub-con", value: "Plumbing Sub-con"},
    { label: "Ceiling Sub-con", value: "Ceiling Sub-con"},
    { label: "Flooring Sub-con", value: "Flooring Sub-con"},
    { label: "Painter Sub-con", value: "Painter Sub-con"},
    { label: "Landscape Sub-con", value: "Landscape Sub-con"}
];
  

export const NumWeeks = [
    {label:"1", value:"1"},
    {label:"2", value:"2"},
    {label:"3", value:"3"},
    {label:"4", value:"4"},
];

export const ContractType = [
    {label:"PO", value:"PO"},
    {label:"LOI", value:"LOI"},
    {label:"LOA", value:"LOA"},
    {label:"SA", value:"SA"},
    {label:"VO", value:"VO"},
    {label:"BQ", value:"BQ"},
    {label:"DWG", value:"DWG"},
];

export const  EOTStatus = [
    { label: 'Requested', value: 'Requested' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Denied', value: 'Denied' }
];
