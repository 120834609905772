import React, { useMemo, useState, useRef, useEffect, forwardRef , useCallback} from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Notiflix from "notiflix";
import { useHistory, Link } from 'react-router-dom';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import ReactTooltip from 'react-tooltip';
import { handleSetPageOptions } from "util/Utility";
import { Block } from "notiflix";
import { Config, ConfigEnum } from 'util/Config';
import ProjectTable from "components/tables/ProjectTable";
import ProjectDao from "data/ProjectDao";

///<summary>
///Author: Sim
///</summary>
const ProjectList = props => {
    const [modal, setModal] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [project, setProject] = useState([]);
    let dao = new ProjectDao();
    const [sneId, setSneId] = useState(null);
    const history = useHistory();
    const formRef = useRef();
    const _tableRef = useRef();
    
  const [listQueryParams, setListQueryParams] = useState();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);
   
    const getProjectList = useCallback(
      async (params) => {
          Block.circle("#projectTable");
          await dao.getProjectList(token,params,1).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
              var data = response[ApiKey._API_DATA_KEY];
              console.log(data);
              setProject(data);
              handleSetPageOptions(_tableRef, response[ApiKey._API_TOTAL_KEY] ?? 0);
            } else {
              Notiflix.Report.failure(
                "Error",
                "Failed to retrieve projects. Please try again later"
              );
            }
          });
    
          Block.remove("#projectTable");
        
    }, [dao, token]);
    
      useEffect(() => {
        listQueryParams && getProjectList(listQueryParams);
      }, [listQueryParams]);
    
      const deleteProject = async (row) => {
        console.log(project)
        var id = row.id;
        let dao = new ProjectDao();
        console.log(id);
        await dao.deleteProject(id, token).then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            Notiflix.Report.success("Success", "Project Deleted",   getProjectList(listQueryParams));
          
          } else {
            Notiflix.Report.failure(
              "Error",
              "Failed to delete project. " + responseJson[ApiKey._API_MESSAGE_KEY]
            );
          }
        });
      };

      const completeProject = async (row) => {
        console.log(project)
        var id = row.id;
        let dao = new ProjectDao();
        console.log(id);
        await dao.completeProject(id, token).then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            Notiflix.Report.success("Success", "Project Completed",   getProjectList(listQueryParams));
          
          } else {
            Notiflix.Report.failure(
              "Error",
              "Failed to complete project. " + responseJson[ApiKey._API_MESSAGE_KEY]
            );
          }
        });
      };

      const closeProject = async (row) => {
        console.log(project)
        var id = row.id;
        let dao = new ProjectDao();
        console.log(id);
        await dao.closeProject(id, token).then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            Notiflix.Report.success("Success", "Project Closed",   getProjectList(listQueryParams));
          
          } else {
            Notiflix.Report.failure(
              "Error",
              "Failed to complete project. " + responseJson[ApiKey._API_MESSAGE_KEY]
            );
          }
        });
      };

    return (
        <>
            <div id="projectTable" style={{ maxHeight: '65vh' }}>
                <ProjectTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h2 className="m-b-0">Project List</h2>                            
                                <div className="btn btn-themed ml-2" onClick={() => history.push(WebUrl._PROJECT_NEW)}>
                                <span>New Project  </span>
                                <i className="fa fa-plus"></i>                            
                                </div>                            
                        </div>
                    )}                    
                    data={project}  
                    tableRef={_tableRef}
                    canPreviousPage={true}
                    canNextPage={true}
                    disabledApiPagination={false}
                    queryParams={listQueryParams}
                    setQueryParams={setListQueryParams}  
                    deleteProject={deleteProject}  
                    completeProject={completeProject}
                    closeProject={closeProject}
                    successCallback= {() => {
                      getProjectList(listQueryParams)}}
                    //tableRef={_tableRef}
                />
            </div>
        </>
    )
}

export default ProjectList;

