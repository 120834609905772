import React, { useMemo } from "react";
import ReactTable from 'components/react-table/ReactTable';

const ContactsTable = ({ data, tableClickEvent, setOpen, tableRef, deleteContactsList, ...rest }) => {
    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Company Name",
            accessor: "companyName",     
          },
          {
            Header: "Mobile Phone",
            accessor: "mobilePhone",     
          },  
          {
            Header: "Email Address",
            accessor: "emailAddress",     
          },  
          {
            Header: "Project Role",
            accessor: "projectRole",
          },  
          {
            Header: "Remarks",
            accessor: "remarks",     
          },
          {
            Header: " ",
            width:70,
            Cell: ({ row, index }) => (
            <button type="button" className="btn expand-theme btn-round"
                    style={{padding:"0px"}}
                    title="Delete Contact"
                    onClick={() => {
                          deleteContactsList(row);
                    }}
                  >
                    <i className="fas fa-times"></i>
              </button>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", textAlign: "right", padding:"0px"},
          },
    ]);
    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default ContactsTable;