import React, { useMemo, useState, useRef, useEffect, forwardRef,useCallback } from "react";
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";  
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import { ProjectRole, ApiKey, Colors } from "util/Constant";
import { Block , Loading} from "notiflix";
import Notiflix from "notiflix";
import CustomerDao from "data/CustomerDao";
import { handleSetPageOptions } from "util/Utility";
import ContactsTable from "components/tables/ContactsTable";
import ContactsSavedTable from "components/tables/ContactsSavedTable";
import { DataArrayTwoTone } from "@mui/icons-material";
import ProjectDao from "data/ProjectDao";
import { isEmpty } from "lodash";

const Contacts = (props) => {
    const { register, control, handleSubmit, setValue, errors, watch, reset, successCallback } = useForm({
        status: 0,
        priority: 0,
    });

    const history = useHistory();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    const [customerList, setCustomerList] = useState([]);
    const [customers, setCustomers] = useState([]);
    const _tableRef = useRef();
    let dao = useMemo(() => new CustomerDao(), []);

    const [listQueryParams, setListQueryParams] = useState();
    const [contactsList, setContactsList] = useState([]);

    const getCustomerList = useCallback(
        async (params) => {
    
          await dao.getCustomerList(token, params).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
              var data = response[ApiKey._API_DATA_KEY];
              var customers = [];
    
              data.map((item) => {
                var obj = {
                    label : item.fullName,
                    value : item.id,
                }
                customers.push(obj);
              });

              setCustomers(data);    
              setCustomerList(customers);
            } else {
              Notiflix.Report.failure(
                "Error",
                "Failed to load customer. Please try again later"
              );
            }
          });
    
        },
        [token, dao]
    );

    useEffect(() => {
      getCustomerList(listQueryParams);
    }, [getCustomerList]);

    const {
        handleNext,
        projectId,
        generalInfo,
        setGeneralInfo
      } = props;

      const deleteContactsList = async(row) => {
        var index = row.index;
        console.log(contactsList);
        Notiflix.Confirm.show(
          "Confirmation",
          "Are you sure to delete this row?",
          "Yes",
          "No",
          function () {
            console.log(contactsList);
            let tempContactsList = [...contactsList];

            tempContactsList.splice(index,1);
            console.log(tempContactsList);
            setContactsList(tempContactsList);          
          }
        );
      }
      const deleteContactsSavedList = async(row) => {
        var id = row.id;
        var index = 0;
        console.log(generalInfo.contacts);
        Notiflix.Confirm.show(
          "Confirmation",
          "Are you sure to delete this row?",
          "Yes",
          "No",
          function () {
            console.log(generalInfo.contacts);
            const updatedContacts = generalInfo.contacts.filter((contact) => contact.id !== id);
              
              // Update the state with the new array
              setGeneralInfo((prevGeneralInfo) => ({
                  ...prevGeneralInfo,
                  contacts: updatedContacts,
              }));   
          }
        );
      }
      
      const onSubmit = async(data) => {
        Notiflix.Confirm.show(
          'Notiflix Confirm',
          'Do you want to continue?',
          'Yes',
          'No',
          async () => {
            Loading.init({
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                svgColor: Colors.THEME,
                messageColor: Colors.WHITE,
            });

            Loading.circle();

    
            let dao = new ProjectDao();
            if(generalInfo){
              var obj ={
                ...data,
                generalInfo,
                contactsList,
                projectId: generalInfo.id
              }
              console.log(obj)//correct edy units use obj/data->generalInfo->units when recording back to db
              await dao.updateContact(obj,token).then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                  let contact = responseJson[ApiKey._API_DATA_KEY];
                  Notiflix.Report.init({ plainText: false });
                  Notiflix.Report.success("Success",`Successfully updated model.`,"Okay",handleNext());
                } else {
                  Notiflix.Report.failure("Error",responseJson[ApiKey._API_MESSAGE_KEY]);
                } 
              })
            }else{
              console.log(data);
              var obj = {
                ...data,
                contactsList,
                projectId
              }
              console.log(obj)
              console.log(projectId)

              await dao.createContact(obj, token).then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                  let contact = responseJson[ApiKey._API_DATA_KEY];
      
                  Notiflix.Report.init({ plainText: false });
                  Notiflix.Report.success(
                    "Success",
                    `Successfully created contact.`,
                    "Okay",
                    handleNext()
                  );
                } else {
                  Notiflix.Report.failure(
                    "Error",
                    responseJson[ApiKey._API_MESSAGE_KEY]
                  );
                }
              })
            }Loading.remove();
        }
        );                 
    }


    const addToContactsList = (data) => {
        //add the contacts into an array for dynamic display n to be stored afterwards
        //find is its undefined(custom select), then prompt notiflix warning
        if(watch("contactName") == undefined || watch("projectRole") == undefined){
          console.log(watch("contactName"));
          Notiflix.Report.failure("Error","Fields are empty. Please fill in the empty fields.");
        }else{
          var findCustomer = customers.find(x => x.id === watch("contactName"));

          console.log(findCustomer);
          setContactsList([...contactsList,
          {
              id: findCustomer.id,
              name: findCustomer.fullName,
              companyName: findCustomer.companyName,
              mobilePhone: findCustomer.mobileNumber,
              emailAddress: findCustomer.email,
              
              projectRole: watch("projectRole"),
              remarks: watch("remarks"),

          }
          ]);
        }

    };

    return (
        <form id="contactsForm" onSubmit={handleSubmit(onSubmit)}>
        <Row style={{backgroundColor:"#F0F9FF", margin: "10px"}}>
                    <Col md={10}>
                        <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                            Contacts - "Project Title"
                        </h1>
                    </Col>
                    <Col md={2}>
                        <button type="reset" className='btn btn-default d-flex-center' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
                    </Col>
                    <Col md={6}>
                    <label className="custom-input-label">Contact Name</label>
                    
                    <CustomSelect
                      control={control}
                      name="contactName"
                      label="Contact Name"
                      placeholder="Choose the contact name"
                      options={customerList}
                      rules={{
                          required: false,
                      }}
                    /> 
                </Col>
                <Col md={6}>
                    <label className="custom-input-label">Project Role</label>
                    <CustomSelect
                      control={control}
                      name="projectRole"
                      label="Project Role"
                      placeholder="Choose the project role"
                      options={ProjectRole}
                      rules={{
                          required: false,
                      }}
                    /> 
                </Col>
                <Col md={12}>
                    <label className="custom-input-label">Remarks</label>
                    <CustomInput
                      {...register("remarks", {
                        required: false,
                      })}
                      name="remarks"
                      label="Remarks"        
                      showLabel
                    />
                </Col>
                <Col md={12}>
                    <br />
                    <button type="button" onClick={() => addToContactsList()} className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", marginRight:"6px", width: "100%"}}>
                        Add Contact to Project
                    </button>
                    <br />
                </Col>
                {isEmpty(generalInfo?.deleted_at) && (<>
                <Col md={12}>                
                  <br />
                  <ContactsTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h1 className="page-subheader">Contacts List</h1>      
                        </div>
                    )}          
                    data={contactsList}
                    deleteContactsList={deleteContactsList}
                    />
                    <br /><br />
                </Col></>)}
                {
                    generalInfo && (
                      <Col md={12}>                
                        <br />
                        <ContactsSavedTable
                          tableTitle={(
                              <div className="d-flex flex-row align-items-center justify-between w-100">
                                  <h1 className="page-subheader">Current Contacts List</h1>      
                              </div>
                          )}          
                          data={generalInfo?.contacts}
                          deleteContactsSavedList={deleteContactsSavedList}
                        />
                        <br /><br />
                      </Col>
                    ) 
                }


                <Col md={12}>
                {(generalInfo?.deleted_at) && (<><button className="btn btn-themed btn-block" onClick={handleNext}>Next</button><br /></>)}
                {isEmpty(generalInfo?.deleted_at) && (<><button type="submit" className="btn btn-themed btn-block">Save & Next</button>
                <br /></>)}
                </Col>
        </Row>
        </form>
    );


}

export default Contacts;