import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";  
import CustomDatePicker from "components/form/CustomDatePicker";
import CustomDateRangePicker from "components/form/CustomDateRangePicker";
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Config, ConfigEnum } from 'util/Config';
import moment from 'moment';
import { NumWeeks, ContractType, EOTStatus, ApiKey, Colors } from "util/Constant";
import  DocumentFileUpload  from "components/form/DocumentFileUpload";
import DocumentTable from "components/tables/DocumentTable";
import DocumentSavedTable from "components/tables/DocumentSavedTable";
import EOTTable from "components/tables/ExtensionOfTimeTable";
import EOTSavedTable from "components/tables/ExtensionOfTimeSavedTable";
import { Block , Loading} from "notiflix";
import Notiflix from "notiflix";
import ProjectDao from "data/ProjectDao";
import { isEmpty } from "lodash";

const dateFns = require( "date-fns");
const { addWeeks } = dateFns;

const Contracts =  (props) => {
    const { register, control, handleSubmit, setValue, errors, watch, reset } = useForm({
            status: 0,
            priority: 0,
        });
    
    const history = useHistory();
    
    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);
    const [showDocument, setShowDocument] = useState(false);
    const [showEOT, setShowEOT] = useState(false);
    const [documentsList, setDocumentsList] = useState([]);
    const [documents, setDocuments] = useState({});
    const [eotList, setEOTList] = useState([]);
    const [eots, setEOTs] = useState({});
    const[contractualStartDate, setContractualStartDate]= useState(new Date());
    const[fabricationDeliveryDate, setFabricationDeliveryDate]= useState();
    const[physicalInstallationDate, setPhysicalInstallationDate]= useState();
    const[testingWiringDate, setTestingWiringDate]= useState();
    const[contractualEndDate, setContractualEndDate]= useState();
    const _tableRef = useRef();
    {/*new function as handler */}
    const showDocumentClicked = (data) => {
      setShowDocument((prev) => !prev);
    };
    const showEOTClicked = (data) => {
      setShowEOT((prev) => !prev);
    };

    const {
        handleNext,
        projectId,
        generalInfo,
        setGeneralInfo
    } = props;

    useEffect(()=> {
      console.log(eotList);
    },[eotList]);

    useEffect(() => {
      reset({
          contractualStartDate: generalInfo?.contractual_start_date || moment(), // Reset with new default value
          fabricationDeliveryInWeeks: generalInfo?.fabrication_delivery_in_weeks || "",
          physicalInstallationInWeeks: generalInfo?.physical_installation_in_weeks || "",
          testingWiringInWeeks: generalInfo?.testing_wiring_in_weeks || "",
          clientRequestedDueDate: generalInfo?.client_requested_due_date || moment(),
          overallProjectDueDate: generalInfo?.overall_project_due_date || moment()

          // Add other fields as needed
      });
      setFabricationDeliveryDate(new Date(generalInfo?.fabrication_delivery_date || moment()));
      setPhysicalInstallationDate(new Date(generalInfo?.physical_installation_date || moment()));
      setTestingWiringDate(new Date(generalInfo?.testing_wiring_date || moment()));
      setContractualStartDate(new Date(generalInfo?.contractual_start_date || moment()));
      setContractualEndDate(new Date(generalInfo?.contractual_end_date || moment()));
    }, [generalInfo, reset]);

    const onSubmit = (data) => {
            console.log(data);
            if(watch("contractualStartDate") == undefined || watch("fabricationDeliveryInWeeks") == undefined ||
              watch("physicalInstallationInWeeks") == undefined || watch("testingWiringInWeeks") == undefined ||
              watch("clientRequestedDueDate") == undefined || watch("overallProjectDueDate") == undefined){
              Notiflix.Report.failure("Error","Fields are empty. Please fill in the empty fields.");
            }else{
              console.log(contractualEndDate)
              Notiflix.Confirm.show(
                'Notiflix Confirm',
                'Do you want to continue?',
                'Yes',
                'No',
                async () => {
                  Loading.init({
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                      svgColor: Colors.THEME,
                      messageColor: Colors.WHITE,
                  });
      
                  Loading.circle();
      
                  data["documents"]= documentsList;
                  data["eots"]= eotList;
        
                let dao = new ProjectDao();
                if(generalInfo){
                  var obj ={
                    ...data,
                    contractualStartDate: changeDateFormat("contractualStartDate"),
                    clientRequestedDueDate: changeDateFormat("clientRequestedDueDate"),
                    overallProjectDueDate: changeDateFormat("overallProjectDueDate"),
                    projectId: generalInfo.id,
                    fabricationDeliveryDate : changeDataDateFormat(fabricationDeliveryDate),
                    physicalInstallationDate : changeDataDateFormat(physicalInstallationDate),
                    testingWiringDate : changeDataDateFormat(testingWiringDate),
                    contractualEndDate : changeDataDateFormat(contractualEndDate),
                    generalInfo
                  }
                  console.log(obj)
                  await dao.updateContract(obj,token).then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                      let contract = responseJson[ApiKey._API_DATA_KEY];
                      Notiflix.Report.init({ plainText: false });
                      Notiflix.Report.success("Success",`Successfully updated model.`,"Okay",handleNext());
                    } else {
                      Notiflix.Report.failure("Error",responseJson[ApiKey._API_MESSAGE_KEY]);
                    } 
                  })

                }else{
                  var obj = {
                    ...data,
                    contractualStartDate: changeDateFormat("contractualStartDate"),
                    clientRequestedDueDate: changeDateFormat("clientRequestedDueDate"),
                    overallProjectDueDate: changeDateFormat("overallProjectDueDate"),
                    projectId,
                    fabricationDeliveryDate : changeDataDateFormat(fabricationDeliveryDate),
                    physicalInstallationDate : changeDataDateFormat(physicalInstallationDate),
                    testingWiringDate : changeDataDateFormat(testingWiringDate),
                    contractualEndDate : changeDataDateFormat(contractualEndDate)
                  }
                  console.log(obj);
                  await dao.createContract(obj, token).then((responseJson) => {
                      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        let contracts = responseJson[ApiKey._API_DATA_KEY];
            
                        Notiflix.Report.init({ plainText: false });
                        Notiflix.Report.success(
                          "Success",
                          `Successfully created contract.`,
                          "Okay",
                          handleNext()
                        );
                      } else {
                        Notiflix.Report.failure(
                          "Error",
                          responseJson[ApiKey._API_MESSAGE_KEY]
                        );
                      }
                    })
                }
                Loading.remove();
              }
              ); 
            }            
    }

    // funtion used to reformat the original date 
    const changeDateFormat = (data) =>{
        return(moment(watch(data)).format("MM/DD/YYYY"))
    }
    const changeDataDateFormat = (data) =>{
        return moment(data).format("MM/DD/YYYY")
    }
    const setupDate = ( data ) =>{
        setContractualStartDate(data);
    };

    const setupFabricationDate = ( data ) =>{
        setFabricationDeliveryDate(addWeeks(contractualStartDate,data));
    };

    const setupInstallationDate = ( data ) =>{
        setPhysicalInstallationDate(addWeeks(fabricationDeliveryDate,data));
    };

    const setupTestingDate = ( data ) =>{
        setTestingWiringDate(addWeeks(physicalInstallationDate,data));
        setContractualEndDate(addWeeks(physicalInstallationDate,data));
    };

    const addToEOTList = async(data) => {
      if(watch("eotFile") == undefined || watch("eotStatus") == undefined){
        Notiflix.Report.failure("Error","Fields are empty. Please fill in the empty fields.");
      }else{
        const [
          eotFileBase64
        ] = await Promise.all([
          blobToBase64(watch("eotFile"))
        ]);
        //add the units into an array for dynamic display n to be stored afterwards
        setEOTList([...eotList,
          {
            eots,
            eotStatus: watch("eotStatus"),
            remarks: watch("eotRemarks"),
            eotDate: changeDateFormat("eotDate"),

            //files handling
            eotFileBase64 : eotFileBase64,

          }
            ]);
            showEOTClicked();
      }
    };
    const addToDocumentsList = async(data) => {
      if(watch("documentFile") == undefined || watch("contractType") == undefined){
        Notiflix.Report.failure("Error","Fields are empty. Please fill in the empty fields.");
      }else{
        const [
          documentFileBase64
        ] = await Promise.all([
          blobToBase64(watch("documentFile"))
        ]);
        //add the units into an array for dynamic display n to be stored afterwards
        setDocumentsList([...documentsList,
          {
            documents,
            contractType: watch("contractType"),
            remarks: watch("remarks"),

            //files handling
            documentFileBase64 : documentFileBase64,

          }
            ]);
            showDocumentClicked();
      }
    };

        const deleteDocumentsList = async(row) => {
          var index = row.index;
          console.log(documentsList);
          Notiflix.Confirm.show(
            "Confirmation",
            "Are you sure to delete this row?",
            "Yes",
            "No",
            function () {
              console.log(documentsList);
              let tempDocumentsList = [...documentsList];
  
              tempDocumentsList.splice(index,1);
              console.log(tempDocumentsList);
              setDocumentsList(tempDocumentsList);          
            }
          );
        }
        const deleteDocumentsSavedList = async(row) => {
          var id = row.id;
          var index = 0;
          console.log(generalInfo.contracts);
          Notiflix.Confirm.show(
            "Confirmation",
            "Are you sure to delete this row?",
            "Yes",
            "No",
            function () {
              console.log(generalInfo.contracts);
              const updatedDocuments = generalInfo.contracts.filter((contracts) => contracts.id !== id);
                console.log(updatedDocuments)
                // Update the state with the new array
                setGeneralInfo((prevGeneralInfo) => ({
                    ...prevGeneralInfo,
                    contracts: updatedDocuments,
                }));   
            }
          );
        }

        const deleteEOT = async(row) => {
          var index = row.index;
          console.log(eotList);
          Notiflix.Confirm.show(
            "Confirmation",
            "Are you sure to delete this row?",
            "Yes",
            "No",
            function () {
              console.log(eotList);
              let tempEOTList = [...eotList];
  
              tempEOTList.splice(index,1);
              console.log(tempEOTList);
              setEOTList(tempEOTList);          
            }
          );
        }
        const deleteSavedEOT = async(row) => {
          var id = row.id;
          var index = 0;
          console.log(generalInfo.eots);
          Notiflix.Confirm.show(
            "Confirmation",
            "Are you sure to delete this row?",
            "Yes",
            "No",
            function () {
              console.log(generalInfo.eots);
              const updatedEOTS = generalInfo.eots.filter((eots) => eots.id !== id);
                console.log(updatedEOTS)
                // Update the state with the new array
                setGeneralInfo((prevGeneralInfo) => ({
                    ...prevGeneralInfo,
                    eots: updatedEOTS,
                }));   
            }
          );
        }

        const blobToBase64 = (blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            
            reader.onloadend = () => {
              // Extract Base64 string from the result
              const base64String = reader.result.split(',')[1];
              resolve(base64String); // Return the Base64 string
            };
      
            reader.onerror = reject;
            reader.readAsDataURL(blob[0]); // This converts the Blob to a Data URL (Base64)
          });
        };


    return (
        <form id="ContractForm" onSubmit={handleSubmit(onSubmit)}>
        <Row style={{backgroundColor:"#F0F9FF", margin: "10px"}}>
            <Col md={10}>
                <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                    Contracts
                </h1>
            </Col>
            <Col md={2}>
                <button type="reset" className='btn btn-default d-flex-center' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", margin:"auto", width:"100%"}}>Reset Fields &nbsp; <i className="fas fa-undo"></i></button>
            </Col>
            <Col md={6}>
                <label className="custom-input-label">Contractual Start Date</label>
                    <CustomDatePicker
                      control={control}
                      {...register("contractualStartDate", false)}
                      name="contractualStartDate"
                      label="Contractual Start Date"       
                      defaultValue={generalInfo?.contractual_start_date || moment()}
                      onChange={setupDate}
                    />
            </Col>
            <Col md={6}>
                <label className="custom-input-label">Fabrication & Delivery (weeks)</label>
                <div style={{display : "inline-flex" , margin : "auto", width: "100%"}}>
                    <div style={{width:"50%", padding: "0px 10px 0px 0px"}}>
                    <CustomSelect
                      control={control}
                      name="fabricationDeliveryInWeeks"
                      label="Fabrication Delivery In Weeks"
                      placeholder="Choose the number of weeks"
                      defaultValue={generalInfo?.fabrication_delivery_in_weeks}
                      options={NumWeeks}
                      rules={{
                          required: true,
                      }}
                      onChange={setupFabricationDate}
                      style={{display:"inline-block", width: "50%"}}
                    /></div>
                    <CustomInput
                      {...register("fabricationDeliveryDate", {
                        required: "Fabrication Delivery Date is required",
                      })}
                      name="fabricationDeliveryDate"
                      label="Fabrication Delivery Date"
                      defaultValue={generalInfo?.fabrication_delivery_date}
                      isEditable = {false}  
                      showLabel
                      value={fabricationDeliveryDate?.toLocaleDateString("en-US", { // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                      style={{display:"inline-block", width: "50%"}}
                    />
                    </div>
            </Col>
            <Col md={6}>
                <label className="custom-input-label">Physical Installation (weeks)</label>
                <div style={{display : "inline-flex" , margin : "auto", width: "100%"}}>
                    <div style={{width:"50%", padding: "0px 10px 0px 0px"}}>
                    <CustomSelect
                      control={control}
                      name="physicalInstallationInWeeks"
                      label="Physical Installation In Weeks"
                      placeholder="Choose the number of weeks"
                      defaultValue={generalInfo?.physical_installation_in_weeks}
                      options={NumWeeks}
                      rules={{
                          required: true,
                      }}
                      onChange={setupInstallationDate}
                      style={{display:"inline-block", width: "50%"}}
                    /></div>
                    <CustomInput
                      {...register("physicalInstallationDate", {
                        required: "Physical Installation Date is required",
                      })}
                      name="physicalInstallationDate"
                      label="Physical Installation Date"
                      defaultValue={generalInfo?.physical_installation_date || moment()}
                      isEditable = {false}  
                      showLabel
                      value={physicalInstallationDate?.toLocaleDateString("en-US", { // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                      style={{display:"inline-block", width: "50%"}}
                    />
                    </div>
            </Col>
            <Col md={6}>
                <label className="custom-input-label">Testing & Wiring (weeks)</label>
                <div style={{display : "inline-flex" , margin : "auto", width: "100%"}}>
                    <div style={{width:"50%", padding: "0px 10px 0px 0px"}}>
                    <CustomSelect
                      control={control}
                      name="testingWiringInWeeks"
                      label="Testing & Wiring In Weeks"
                      placeholder="Choose the number of weeks"
                      defaultValue={generalInfo?.testing_wiring_in_weeks}
                      options={NumWeeks}
                      rules={{
                          required: true,
                      }}
                      onChange={setupTestingDate}
                      style={{display:"inline-block", width: "50%"}}
                    /></div>
                    <CustomInput
                      {...register("testingWiringDate", {
                        required: "Testing & Wiring Date is required",
                      })}
                      name="testingWiringDate"
                      label="Teating & Wiring Date"
                      defaultValue={generalInfo?.testing_wiring_date || moment()}
                      isEditable = {false}  
                      showLabel
                      value={testingWiringDate?.toLocaleDateString("en-US", { // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                      style={{display:"inline-block", width: "50%"}}
                    />
                    </div>
            </Col>
            <Col md={4}>
                <label className="custom-input-label">Contractual End Date</label>
                    <CustomInput
                      {...register("contractualEndDate", {
                        required: "Contractual End Date is required",
                      })}
                      name="contractualEndDate"
                      label="Contractual End Date"
                      defaultValue={generalInfo?.contractual_end_date || moment()}
                      isEditable = {false}  
                      showLabel
                      value={contractualEndDate?.toLocaleDateString("en-US", { // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                    />   
            </Col>
            <Col md={4}>
                <label className="custom-input-label">Client Requested Due Date</label>
                    <CustomDatePicker
                      control={control}
                      {...register("clientRequestedDueDate", false)}
                      name="clientRequestedDueDate"
                      label="Client Requested Due Date"
                      defaultValue={generalInfo?.client_requested_due_date || moment()}
                      onChange={null}
                    />
            </Col>
            <Col md={4}>
                <label className="custom-input-label">Overall Project Due Date</label>
                    <CustomDatePicker
                      control={control}
                      {...register("overallProjectDueDate", false)}
                      name="overallProjectDueDate"
                      label="Overall Project Due Date"
                      defaultValue={generalInfo?.overall_project_due_date || moment()}
                      onChange={null}
                    />
            </Col>


            {isEmpty(generalInfo?.deleted_at) && (<><Col md={10}>                
                <br />
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                      Lists of Extension Of Time
                  </h1>
            </Col>
            <Col md={2} style={{marginTop: "auto"}}>
                <div className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center"}} onClick={showEOTClicked}>
                    <span>Add new EOT +</span>
                </div> </Col>
                    { 
                    showEOT && (
                        <Col md={12}>
                            <Row style={{backgroundColor:"white", margin: "10px"}}>
                                <Col><br /></Col>
                                    <Col md={12}>
                                        <h4>
                                            Add New EOT
                                        </h4>
                                        <DocumentFileUpload
                                          control={control}
                                          name="eotFile"
                                          setDocuments={setEOTs}
                                        />
                                    </Col>
                                    <Col md={12}><br /></Col>
                                    <Col md={6}>
                                        <label className="custom-input-label">EOT Date</label>
                                            <CustomDatePicker
                                              control={control}
                                              {...register("eotDate", false)}
                                              name="eotDate"
                                              label="EOT Date"
                                              defaultValue={moment()}
                                              onChange={null}
                                            />
                                    </Col>
                                    <Col md={6}>
                                        <label className="custom-input-label">Status</label>
                                            <CustomSelect
                                                control={control}
                                                name="eotStatus"
                                                label="Status"
                                                placeholder="Choose the status"
                                                options={EOTStatus}
                                                rules={{
                                                    required: true,
                                                }}
                                            /> 
                                    </Col>
                                    <Col md={12}>
                                        <label className="custom-input-label">Remarks</label>
                                            <CustomInput
                                              {...register("eotRemarks", {
                                                required: false,
                                              })}                    
                                              name="eotRemarks"
                                              label="Remarks"           
                                              showLabel
                                            />
                                    </Col>
                                          
                                          
                                    <Col md={12}>
                                        <br />
                                        <button type="button" onClick={() => addToEOTList()} className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", marginRight:"6px", width: "100%"}}>Add to EOT List</button>
                                        <br />
                                    </Col>
                            </Row>
                        </Col> 

                    ) 
            }
                <Col md={12}>                
                    <br />
                    <EOTTable
                      tableTitle={(
                          <div className="d-flex flex-row align-items-center justify-between w-100">
                              <h1 className="page-subheader">EOT List</h1>      
                          </div>
                      )}         
                      data={eotList}
                      deleteEOT={deleteEOT}
                      tableRef={_tableRef} 
                    />
                    <br /><br />
                </Col></>)}
                {generalInfo && ( 
                <Col md={12}>                
                    <br />
                    <EOTSavedTable
                      tableTitle={(
                          <div className="d-flex flex-row align-items-center justify-between w-100">
                              <h1 className="page-subheader">Current EOT List</h1>      
                          </div>
                      )}         
                      data={generalInfo?.eots}
                      deleteSavedEOT={deleteSavedEOT}
                      tableRef={_tableRef} 
                    />
                    <br /><br />
                </Col>
            )}
            {isEmpty(generalInfo?.deleted_at) && (<>
            <Col md={10}>                
                <br />
                  <h1 className="page-subheader" style={{borderBottom: "2px solid #77C0F173"}}>
                      Lists of Contractual Documents
                  </h1>
            </Col>
            <Col md={2} style={{marginTop: "auto"}}>
                <div className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center"}} onClick={showDocumentClicked}>
                    <span>Add new document +</span>
                </div> </Col>
                    { /* action handler if __ is true then to what from here eh is put in the table inside here then rmb onClick ^ put the function that setShowUnit to true eh function*/
                    showDocument && (
                        <Col md={12}>
                            <Row style={{backgroundColor:"white", margin: "10px"}}>
                                <Col><br /></Col>
                                    <Col md={12}>
                                        <h4>
                                            Add New Document
                                        </h4>
                                        <DocumentFileUpload
                                          control={control}
                                          name="documentFile"
                                          setDocuments={setDocuments}
                                        />
                                    </Col>
                                    <Col><br /></Col>
                                    <Col md={12}>
                                        <label className="custom-input-label">Contract Type</label>
                                            <CustomSelect
                                                control={control}
                                                name="contractType"
                                                label="Contract Type"
                                                placeholder="Choose the contract type"
                                                options={ContractType}
                                                rules={{
                                                    required: true,
                                                }}
                                            /> 
                                    </Col>
                                    <Col><br /></Col>
                                    <Col md={12}>
                                        <label className="custom-input-label">Remarks</label>
                                            <CustomInput
                                              {...register("remarks", {
                                                required: false,
                                              })}                    
                                              name="remarks"
                                              label="Remarks"           
                                              showLabel
                                            />
                                    </Col>
                                          
                                          
                                    <Col md={12}>
                                        <br />
                                        <button type="button" onClick={() => addToDocumentsList()} className='btn btn-default d-flex-center filter-btn' style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", marginRight:"6px", width: "100%"}}>Add to Documents List</button>
                                        <br />
                                    </Col>
                            </Row>
                        </Col> 

                    ) 
            }
            {<Col md={12}>                
                  <br />
                  <DocumentTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h1 className="page-subheader">Documents List</h1>      
                        </div>
                    )}         
                    data={documentsList}
                    deleteDocumentsList={deleteDocumentsList}
                />
                <br /><br />
            </Col>}
            </>)}
            {
              generalInfo && (
                <Col md={12}>                
                  <br />
                  <DocumentSavedTable
                    tableTitle={(
                        <div className="d-flex flex-row align-items-center justify-between w-100">
                            <h1 className="page-subheader">Current Documents List</h1>      
                        </div>
                    )}         
                    data={generalInfo?.contracts}
                    deleteDocumentsSavedList={deleteDocumentsSavedList}
                    tableRef={_tableRef} 
                  />
                  <br /><br />
                </Col>
              ) 
            }
            <Col md={12}>
                {(generalInfo?.deleted_at) && (<><button className="btn btn-themed btn-block" onClick={handleNext}>Next</button><br /></>)}
                {generalInfo && isEmpty(generalInfo?.deleted_at) && (<><button className="btn btn-default d-flex-center filter-btn" style={{color:"#0099FF" , borderColor:"#0099FF", justifyContent:"center", marginRight:"6px", width: "100%"}} onClick={handleNext}>Next</button>
                  <br /></>)}
                {isEmpty(generalInfo?.deleted_at) && (<><button type="submit" className="btn btn-themed btn-block">Save & Next</button>
                <br /></>)}
            </Col> 
            </Row>
            </form>
        )
    }
    
    export default Contracts;