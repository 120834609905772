import React, { useMemo } from "react";
import ReactTable from 'components/react-table/ReactTable';

const EOTTable = ({ data, tableClickEvent, setOpen, tableRef, deleteEOT, ...rest }) => {
    
    const columns = useMemo(() => [
          {
            Header: "EOT File Name",
            accessor: "eots.name",     
          },
          {
            Header: "File Size",
            accessor: "eots.fileSize",     
          },  
          {
            Header: "EOT Status",
            accessor: "eotStatus",     
          },  
          {
            Header: "EOT Date",
            accessor: "eotDate",     
          },  
          {
            Header: "Remarks",
            accessor: "remarks",   
          }, 
          {
            Header: " ",
            width:70,
            Cell: ({ row, index }) => (
              <button type="button" className="btn expand-theme btn-round"
              style={{padding:"0px"}}
              title="Delete EOT"
              onClick={() => {
                    deleteEOT(row.original);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible", textAlign: "right", padding:"0px"},
          },

    ]);

    return <ReactTable columns={columns} data={data} ref={tableRef} {...rest} />;
}

export default EOTTable;